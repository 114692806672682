import React from "react";
// @ts-ignore
import microsoftLogo from "../assets/img/companies/microsoft.png";
// @ts-ignore
import boltLogo from "../assets/img/companies/boltLogo.png";
// @ts-ignore
import gorgiasLogo from "../assets/img/companies/gorgiasLogo.png";
// @ts-ignore
import hylandLogo from "../assets/img/companies/hylandLogo.png";
// @ts-ignore
import meilleursLogo from "../assets/img/companies/meilleursLogo.png";
// @ts-ignore
import morningLogo from "../assets/img/companies/morningLogo.png";
// @ts-ignore
import enedisLogo from "../assets/img/companies/enedis.png";
// @ts-ignore
import qontoLogo from "../assets/img/companies/qontoLogo.png";
// @ts-ignore
import hecLogo from "../assets/img/companies/HEC.png";
// @ts-ignore
import Layout from "../layouts";

const PersonalAccessPage = () => {
  return (
    <div>
      <title>Stand a chance to win a coaching session</title>
      <Layout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
            paddingTop: "50px",
          }}
        >
          <iframe
            title="Coachello Personal Access"
            style={{ width: "60%", height: "80%", border: "none" }}
            src="https://lo563s976ew.typeform.com/to/oajp5BRl"
            allowFullScreen
          ></iframe>
        </div>

        <div className="section bg-warm-dark">
          <h2 className="text-center md:text-left">
            Join amazing companies like
          </h2>
          <div className="py-5 flex flex-wrap justify-center md:justify-start [&>div>img]:h-12">
            <div>
              <img src={microsoftLogo} />
            </div>
            <div>
              <img src={boltLogo} />
            </div>
            <div>
              <img src={gorgiasLogo} />
            </div>
            <div>
              <img src={hylandLogo} />
            </div>
            <div>
              <img src={meilleursLogo} />
            </div>
            <div>
              <img src={morningLogo} />
            </div>
            <div>
              <img src={enedisLogo} />
            </div>
            <div>
              <img src={qontoLogo} />
            </div>
            <div>
              <img src={hecLogo} />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default PersonalAccessPage;
